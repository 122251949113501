import axios from 'axios';
import Vue from "vue";

const axiosIns = axios.create({
    baseURL: 'https://sc1.clicklab.app/api/', // Set the base URL for the API
    headers: {
        'Content-Type': 'application/json',
    }
});

// Add a request interceptor to attach the token to every request
axiosIns.interceptors.request.use(
    (config) => {
        const token = JSON.parse(localStorage.getItem('accessToken'));
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

Vue.prototype.$http = axiosIns;

export default axiosIns;
